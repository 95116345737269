import { BindingStatus, Modules } from '@/enums';
import { defineStore } from 'pinia';
import Api from '~utils/Api';
import { ITable } from '@/components/TableComponent/types';
import { IService } from '@/components/tabs/types';
import { ConstantParams } from '../types';
import { useMainStore } from '../index';
import { useOrgsStore } from '../orgs/orgsModule';
import {
  IContract, ArchiveType, IDocType, ISubDoer, IPayment, IAct, ActInPayment, NewContract,
} from './types';

export const useContractsStore = defineStore('contracts', {
  state: () => ({
    constantParams: useMainStore().constantParams as ConstantParams,
    currentOrgId: useOrgsStore().currentOrgId,
    contracts: {} as ITable<IContract>,
    currentContract: null as IContract | null,
    currentLoadType: 2 as ArchiveType,
    docTypes: {} as ITable<IDocType>,
    currentTabData: {} as ITable,
    subDoers: [] as ISubDoer[],
    currentSubdoer: undefined as ISubDoer | undefined,
    selectedServices: [] as IService[],
    payments: {} as ITable<IPayment>,
    acts: {} as ITable<IAct>,
    selectedAct: null as IAct | null,
    selectedPayment: null as IPayment | null,
    idActPay: null as number | null,
  }),

  getters: {
    currentContractId: (state) => (
      state.currentContract ? state.currentContract.id_contract : null
    ),
    docTypesForGenerate: (state) => state.docTypes?.data
      .filter((item) => [1, 2].includes(item.upload_flag)),
    docTypesForAttach: (state) => state.docTypes?.data
      .filter((item) => [0, 2].includes(item.upload_flag)),
    bindingStatus: (state) => {
      if (state.idActPay) {
        return BindingStatus.BINDED;
      }
      // if (!state.selectedPayment) {
      //   return BindingStatus.NOT_BINDED;
      // }
      // const { acts } = state.selectedPayment;
      // try {
      //   const extractedActs = JSON.parse(acts);
      //   if (extractedActs.find((act: ActInPayment) => act.id_act === state.selectedAct?.id)) {
      //     return BindingStatus.BINDED;
      //   }
      // } catch (error) {
      //   return BindingStatus.NOT_BINDED;
      // }
      return BindingStatus.NOT_BINDED;
    },
  },

  actions: {
    async loadContracts() {
      if (!this.currentOrgId) {
        return;
      }
      const data = {
        ...this.constantParams,
        id_org: this.currentOrgId,
        Name_event: 'GET:contracts_list',
        Name_module: Modules.CALCULATION,
        archive: this.currentLoadType,
      };
      const result = await Api.get(data);
      if (result.data && result.structure) {
        this.contracts = {
          data: result.data as IContract[],
          structure: result.structure,
        };
      }
    },
    async loadDocTypes() {
      const data = {
        ...this.constantParams,
        Name_event: 'GET:doc_types',
        Name_module: Modules.CALCULATION,
      };
      const result = await Api.get(data);
      if (result.data && result.structure) {
        this.docTypes = {
          data: result.data as IDocType[],
          structure: result.structure,
        };
      }
    },
    selectContract(params: Record<string, unknown>) {
      if (params.id_contract || params.id_contract === 0) {
        const selectedContract = this.contracts.data
          .find((item) => item.id_contract === params.id_contract);
        if (selectedContract) {
          this.selectedAct = null;
          this.currentContract = selectedContract;
          this.loadSubdoers();
        }
      }
    },
    async addAgent(params: Record<string, unknown>) {
      console.log(params);
    },
    async loadCurrentTabData(event: string, params: Record<string, unknown> = {}) {
      const payload = {
        ...params,
        id_contract_sub_doer: this.currentSubdoer ? this.currentSubdoer.id_contract_sub_doer : 0,
      };
      const result = await useMainStore().dispatchEvent(event, payload);
      if (result) {
        this.currentTabData = result as ITable;
      }
    },
    async loadSubdoers() {
      if (!this.currentContractId) {
        return;
      }
      const data = {
        ...this.constantParams,
        id_org: this.currentOrgId,
        Name_event: 'GET:sub_doers',
        id_contract: this.currentContractId,
      };
      const result = await Api.get(data) as unknown as ITable<ISubDoer>;
      if (result) {
        this.subDoers = result.data;
      }
    },
    setSubdoer(id: number | undefined) {
      this.currentSubdoer = this.subDoers.find((item) => item.id_contract_sub_doer === id);
      this.loadCurrentTabData('GET:services_list', {
        id_contract: this.currentContract?.id_contract,
        id_contract_sub_doer: id || 0,
      });
    },
    selectServices(services: IService[]) {
      this.selectedServices = services;
    },
    async bindSubdoers() {
      const event = 'POST:sub_doers';
      const params = {
        id_contract: this.currentContractId,
        id_contract_sub_doer: this.currentSubdoer?.id_contract_sub_doer,
        json_str_services: JSON.stringify(this.selectedServices.map((el) => el.id_service)),
        json_str: '',
      };
      const result = await useMainStore().dispatchEvent(event, params) as ITable;
      const text = result.data[0].action_text;
      if (text) {
        useMainStore().showMessage(text);
      }
      this.currentSubdoer = undefined;
      this.selectedServices = [];
      this.loadCurrentTabData('GET:services_list', {
        id_contract: this.currentContract?.id_contract,
      });
    },
    async loadPayments() {
      const data = {
        ...this.constantParams,
        id_org: this.currentOrgId,
        Name_event: 'GET:payment_list',
      };
      const result = await Api.get(data) as unknown as ITable<IPayment>;
      if (result) {
        this.payments = result;
      }
    },
    async loadActs() {
      const params = {
        ...this.constantParams,
        id_org: this.currentOrgId,
        Name_event: 'GET:act_list',
        id_contract: this.currentContractId,
      };
      const result = await Api.get(params) as unknown as ITable<IAct>;
      if (result) {
        this.acts = result as ITable<IAct>;
      }
    },
    selectAct(value: number | null) {
      console.log(value);
      if (value !== null) {
        const act = this.acts.data.find((el) => el.id === value);
        if (act) {
          this.selectedAct = act;
          return;
        }
      }
      this.selectedAct = null;
    },
    selectPayment(value: number | null) {
      if (value !== null) {
        const payment = this.payments.data.find((el) => el.id_pay === value);
        if (payment) {
          this.selectedPayment = payment;
          return;
        }
      }
      this.selectedPayment = null;
    },
    setIdActPay(id: number | null) {
      this.idActPay = id;
    },
    async saveContract(params: NewContract) {
      const data = {
        ...this.constantParams,
        ...params,
        Name_event: 'POST:contract',
        Name_module: Modules.WEBMANAGERS,
      };
      const result = await Api.post(data);
      if (!result.errorType) {
        return true;
      }
      return false;
    },
  },
});

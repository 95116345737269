import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { useMainStore } from '@/store';
import { useOrgsStore } from '~store/orgs/orgsModule';
import App from '@/App.vue';
import { useContractsStore } from '@/store/contracts/contractsModule';
import {
  downloadDocument, IDownloadParams, downloadFile, uploadFile,
} from '@/composables';
import { DatesRange } from '@/store/types';
import { usePatientsStore } from '@/store/patients/patientsModule';
import {
  Dates,
  IEmployeeList,
  IPatient,
  IStaff,
} from '@/store/patients/types';
import { IService } from '@/components/tabs/types';
import { useAuthStore } from '@/store/auth/authModule';
import { Org } from '@/store/orgs/types';
import router from '@/router';

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);

export const actions = {
  logout: () => useAuthStore().logout(),
  setCurrentOrg: (args: any) => useOrgsStore().setCurrentOrg(args),
  getFullOrgList: useOrgsStore().getFullOrgList,
  openModal: (id: string, args: any) => useMainStore().openModal(id, args),
  // eslint-disable-next-line max-len
  dispatchEvent: (eventName: string, params: Record<string, unknown> | null) => useMainStore().dispatchEvent(eventName, params),
  addTreeItem: (params: Record<string, unknown>) => useMainStore().dispatchEvent('POST:node_create_v2', params),
  removeTreeItem: (params: Record<string, unknown>) => useMainStore().dispatchEvent('POST:node_del_v2', params),
  editTreeItem: (params: Record<string, unknown>) => useMainStore().dispatchEvent('POST:node_rename', params),
  changeTreeItem: (params: Record<string, unknown>) => useMainStore().dispatchEvent('POST:node_retype', params),
  cloneTreeItem: (params: Record<string, unknown>) => useMainStore().dispatchEvent('POST:tree_clone', params),
  selectContract: (params: Record<string, unknown>) => useContractsStore().selectContract(params),
  addAgent: (params: Record<string, unknown>) => useContractsStore().addAgent(params),
  downloadDoc: (params: IDownloadParams) => downloadDocument(params),
  setDatesRange: (dates: DatesRange) => useMainStore().setDatesRange(dates),
  downloadFile: (url: string) => downloadFile(url),
  uploadFile: (param: Record<string, unknown>) => uploadFile(param),
  getEmployee: (params: Record<string, unknown>) => useMainStore().dispatchEvent('GET:employee', params),
  loadEmployeeLists: () => usePatientsStore().loadEmployeeLists(),
  loadEmployee: (params: Record<string, unknown>) => usePatientsStore().loadEmployee(params),
  selectServices: (params: IService[]) => useContractsStore().selectServices(params),
  loadPatientsByContract: () => usePatientsStore().loadPatientsByContract(),
  setDates: (dates: Dates) => usePatientsStore().setDates(dates),
  loadPatientData: (patient: IPatient) => usePatientsStore().loadPatientData(patient),
  setCurrentStaff: (staff: IStaff) => usePatientsStore().setCurrentStaff(staff),
  refreshActs: () => useContractsStore().loadActs(),
  refreshPayments: () => useContractsStore().loadPayments(),
  switchOrg: (org: Org) => {
    router.push({ name: 'Orgs' });
    useOrgsStore().setCurrentOrg(org);
  },
  employeeToTree: (list: IEmployeeList) => usePatientsStore().employeeToTree(list),
  syncData: (params: any) => console.log('syncData', params),
  redirectToPatientsTable: (params: any) => {
    router.push({ name: 'Structure' });
    usePatientsStore().getTableData('GET:patient_org_list', { ...params }, 0, false);
  },
};

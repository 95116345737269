export const getSumByField = (arr: Record<string | number, unknown>[], field: string): number => {
  if (!arr.length) {
    return 0;
  }
  return Number(arr
    .map((el) => el[field] as number || 0)
    .reduce((acc, item) => acc + item, 0)
    .toFixed(2));
};

export const sumFieldValues = <T>(array: T[], fieldName: keyof T): string => {
  const result = array.reduce((sum, obj) => {
    const value = obj[fieldName];
    if (typeof value === 'number') {
      return sum + value;
    }
    if (typeof value === 'string' && !Number.isNaN(parseFloat(value))) {
      return sum + parseFloat(value);
    }
    return sum;
  }, 0);
  const roundedSum = parseFloat(result.toFixed(2));
  const formattedSum = roundedSum.toLocaleString('ru-RU', {
    useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2,
  });
  return formattedSum;
};
